import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import moment = require("moment");
import {
    IFullWarehouseInspectionWarehouseInspectionOperations,
    IFullWarehouseInspectionWarehouseInspectionOperationsSources,
} from "../../../WarehouseInspectionsService";
import { WarehouseInspectionAction as WarehouseInspectionOperationType } from "./Enums/WarehouseInspectionAction";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel, IDataSourceListener, IDataSource } from "../../../../DataSources/IDataSource";
import { Column, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDocumentsService } from "../../../../Invoices/DocumentsService";
import { With } from "../../../../Components/IfIfNotWith";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { ArticlesDataSource } from "../../../../DataSources/ArticlesDataSource";
import { WarehousesDataSource, IWarehousesDataSourceModel } from "../../../../DataSources/WarehousesDataSource";
import { IArticle } from "../../../../ProlifeSdk/interfaces/warehouse/IArticlesService";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IWarehouseWithStockInfo } from "../../../../ProlifeSdk/interfaces/warehouse/IWarehousesService";

@DetectClassChanges
export class WarehouseInspectionWarehouseInfo implements IDataSourceListener {
    @DetectChanges
    public DestinationWarehouseId: ko.Observable<number> = ko.observable();
    public DestinationWarehouse: string;
    public DestinationWarehouseStock: number;
    public CustomerId: number;
    public CustomerName: ko.Observable<string> = ko.observable();
    public JobOrderId: number;
    public JobOrderName: ko.Observable<string> = ko.observable();

    @DetectChanges
    public Operations: ko.ObservableArray<WarehouseInspectionOperationInfo> = ko.observableArray([]);
    public ShowOperations: ko.Observable<boolean> = ko.observable(false);

    public isChanged: ko.Observable<number> = ko.observable(0);

    public OperationsProblemsAlert: ko.Computed<boolean>;
    public OperationsNumber: ko.Computed<number>;

    public WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();

    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(
        private operationsInfo: IFullWarehouseInspectionWarehouseInspectionOperations[],
        private operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ) {
        this.WarehousesDataSource.setGetAllWarehouses(true);

        const firstOp = this.operationsInfo.firstOrDefault();

        this.DestinationWarehouseId(firstOp?.FKDestinationWarehouse);
        this.DestinationWarehouse = firstOp?.DestinationWarehouse;
        this.DestinationWarehouseStock = firstOp?.DestinationWarehouseStock ?? 0;
        this.CustomerId = firstOp?.CustomerId;
        this.CustomerName(firstOp?.CustomerName);
        this.JobOrderId = firstOp?.JobOrderId;
        this.JobOrderName(firstOp?.JobOrderName);

        this.OperationsNumber = ko.computed(() => {
            return this.Operations().length;
        });

        this.OperationsProblemsAlert = ko.computed(() => {
            return this.Operations().filter((o) => o.Alert()).length > 0;
        });

        this.loadOperations(this.operationsInfo, this.operationsSources);

        this.isChanged(0);
    }

    public addOperation(
        operationData: IFullWarehouseInspectionWarehouseInspectionOperations = null,
        operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[] = []
    ): void {
        if (operationData && this.operationAlreadyExists(operationData)) {
            this.infoToastService.Warning(TextResources.Warehouse.WarehouseOperationAlreadyExists);
            return;
        }

        if (!operationData) operationData = {} as IFullWarehouseInspectionWarehouseInspectionOperations;

        operationData.FKDestinationWarehouse = this.DestinationWarehouseId();
        operationData.DestinationWarehouse = this.DestinationWarehouse;
        operationData.DestinationWarehouseStock = this.DestinationWarehouseStock;
        operationData.OperationType = WarehouseInspectionOperationType.WarehouseTransfer;

        this.appendOperations([operationData], operationsSources);
    }

    public mergeOperationsInfo(
        operationsInfo: IFullWarehouseInspectionWarehouseInspectionOperations[],
        operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ) {
        const actualOperations = this.Operations();
        const newOperations = [];

        for (const operation of operationsInfo) {
            const existingOperation = actualOperations.firstOrDefault(
                (ao) => ao.ArticleId() === operation.FKArticle && ao.OperationType() === operation.OperationType
            );
            if (!existingOperation) {
                newOperations.push(operation);
            } else {
                existingOperation.merge(
                    operation,
                    operationsSources.filter((s) => s.FKInspectionOperation === operation.Id)
                );
            }
        }

        this.appendOperations(newOperations, operationsSources);
    }

    public getData(): IFullWarehouseInspectionWarehouseInspectionOperations[] {
        return this.Operations().map((o) => o.getData());
    }

    public getSourcesData(): IFullWarehouseInspectionWarehouseInspectionOperationsSources[] {
        return this.Operations().selectMultiple((o) => o.getSourcesData());
    }

    public operationAlreadyExists(operationData: IFullWarehouseInspectionWarehouseInspectionOperations): boolean {
        return !!this.Operations().firstOrDefault(
            (o) =>
                o.SourceWarehouseId() === operationData.FKSourceWarehouse &&
                o.ArticleId() === operationData.FKArticle &&
                o.OperationType() === operationData.OperationType &&
                o.Id !== operationData.Id
        );
    }

    public onItemSelected(sender: WarehousesDataSource, model: IWarehousesDataSourceModel): void {
        const warehouse = model?.model;
        this.setWarehouseData(warehouse);
    }

    public onItemDeselected(sender: WarehousesDataSource, model: IWarehousesDataSourceModel): void {
        const warehouse = model?.model;
        this.setWarehouseData(warehouse);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onSelect2Cleared(sender: WarehousesDataSource): void {
        this.setWarehouseData(null);
        this.Operations([]);
    }

    public async canSelectItem(sender: WarehousesDataSource, model: IWarehousesDataSourceModel): Promise<boolean> {
        const actualWarehouseId = this.DestinationWarehouseId();

        if (actualWarehouseId === model?.model?.Id || this.OperationsNumber() === 0) return true;

        if (this.OperationsNumber() > 0) {
            const confirm = await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.ComfirmDestinationWarehouseChangesOnInspectionRow,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );
            if (!confirm) return false;

            this.Operations([]);
        }

        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public async canClearSelection(sender: WarehousesDataSource): Promise<boolean> {
        if (this.OperationsNumber() > 0) {
            const confirm = await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.ComfirmDestinationWarehouseChangesOnInspectionRow,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );
            if (!confirm) return false;
        }

        return true;
    }

    public dispose() {
        // Nothing to do
    }

    private setWarehouseData(warehouse: IWarehouseWithStockInfo) {
        this.DestinationWarehouseId(warehouse?.Id);
        this.DestinationWarehouse = warehouse?.Name;
        this.DestinationWarehouseStock = warehouse?.TotalStock ?? 0;
        this.CustomerId = warehouse?.CustomerId;
        this.CustomerName(warehouse?.CustomerName);
        this.JobOrderId = warehouse?.JobOrderId;
        this.JobOrderName(warehouse?.JobOrderName);
    }

    private loadOperations(
        operationsInfo: IFullWarehouseInspectionWarehouseInspectionOperations[],
        operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ): void {
        const operations = this.createOperations(operationsInfo, operationsSources);
        operations.sort((o1, o2) => this.compareOperations(o1, o2));
        this.Operations(operations);
    }

    private appendOperations(
        operationsInfo: IFullWarehouseInspectionWarehouseInspectionOperations[],
        operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ): void {
        const operations = this.createOperations(operationsInfo, operationsSources);
        const allOperations = this.Operations().concat(operations);
        allOperations.sort((o1, o2) => this.compareOperations(o1, o2));
        this.Operations(allOperations);
    }

    private createOperations(
        operationsInfo: IFullWarehouseInspectionWarehouseInspectionOperations[],
        operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ): WarehouseInspectionOperationInfo[] {
        const operations = [];
        let lastOperation: WarehouseInspectionOperationInfo = null;
        for (const info of operationsInfo) {
            if (
                !lastOperation ||
                lastOperation.ArticleId() !== info.FKArticle ||
                lastOperation.SourceWarehouseId() !== info.FKSourceWarehouse
            ) {
                lastOperation = new WarehouseInspectionOperationInfo(
                    info,
                    operationsSources.filter((s) => s.FKInspectionOperation === info.Id),
                    this
                );
                operations.push(lastOperation);
            }
        }
        return operations;
    }

    private compareOperations(o1: WarehouseInspectionOperationInfo, o2: WarehouseInspectionOperationInfo): number {
        if (o1.SourceWarehouse < o2.SourceWarehouse) return -1;
        else if (o1.SourceWarehouse > o2.SourceWarehouse) return 1;
        else if (o1.SourceWarehouseId < o2.SourceWarehouseId) return -1;
        else if (o1.SourceWarehouseId > o2.SourceWarehouseId) return 1;
        else if (o1.ArticleCode < o2.ArticleCode) return -1;
        else if (o1.ArticleCode > o2.ArticleCode) return 1;
        if (o1.OperationType > o2.OperationType) return -1;
        else if (o1.OperationType < o2.OperationType) return 1;
        else return 0;
    }
}

type OperationSourceInfo = {
    DocumentId: number;
    DocumentDate: Date;
    DocumentNumber: string;
    DocumentLabel: string;
    VatRegisterName: string;

    Amount: number;
};

@DetectClassChanges
export class WarehouseInspectionOperationInfo implements IDataSourceListener {
    public get Id(): number {
        return this.info.Id;
    }

    @DetectChanges
    public ArticleId: ko.Observable<number> = ko.observable();
    public ArticleDescription: string;
    public ArticleCode: string;
    public EanCode: string;
    public MefCode: string;
    public ArticleStock: ko.Observable<number> = ko.observable();
    @DetectChanges
    public SourceWarehouseId: ko.Observable<number> = ko.observable();
    public SourceWarehouse: string;
    @DetectChanges
    public RequestedAmount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public MovementAmount: ko.Observable<number> = ko.observable();
    @DetectChanges
    public ActualAmount: ko.Observable<number> = ko.observable();
    public OperationType: ko.Observable<WarehouseInspectionOperationType> = ko.observable();
    @DetectChanges
    public Done: ko.Observable<boolean> = ko.observable();

    public VerifiedArticleCode: boolean;
    public VerifiedSourceWarehouse: boolean;
    public VerifiedDestinationWarehouse: boolean;
    public VerifiedArticleCodeOnDestinationDefaultWarehouse: boolean;

    public VerifiedArticleCodeTitle: string;
    public VerifiedSourceWarehouseTitle: string;
    public VerifiedDestinationWarehouseTitle: string;
    public VerifiedArticleCodeOnDestinationDefaultWarehouseTitle: string;

    public isChanged: ko.Observable<number> = ko.observable(0);
    public Alert: ko.Observable<boolean> = ko.observable(false);
    public AlertMessage: ko.Observable<string> = ko.observable("");

    public ShowSources: ko.Observable<boolean> = ko.observable(false);
    public OperationsSources: ko.ObservableArray<OperationSourceInfo> = ko.observableArray([]);

    public ArticlesDataSource: ArticlesDataSource = new ArticlesDataSource();
    public WarehousesDataSource: WarehousesDataSource = new WarehousesDataSource();

    private sources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[] = [];

    @LazyImport(nameof<IDocumentsService>())
    private documentsService: IDocumentsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;

    constructor(
        private info: IFullWarehouseInspectionWarehouseInspectionOperations,
        private originalSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[],
        private warehouse: WarehouseInspectionWarehouseInfo
    ) {
        this.WarehousesDataSource.setGetAllWarehouses(true);

        this.ArticleId(this.info.FKArticle);
        this.ArticleDescription = this.info.ArticleDescription;
        this.ArticleCode = this.info.ArticleCode;
        this.EanCode = this.info.EanCode;
        this.MefCode = this.info.MefCode;
        this.ArticleStock(this.info.DestinationWarehouseStock ?? 0);
        this.SourceWarehouseId(this.info.FKSourceWarehouse);
        this.SourceWarehouse = this.info.SourceWarehouse;
        this.MovementAmount(this.info.MovementAmount);
        this.ActualAmount(this.info.ActualAmount);
        this.OperationType(this.info.OperationType);
        this.Done(this.info.Done);
        this.VerifiedArticleCode = this.info.VerifiedArticleCode;
        this.VerifiedSourceWarehouse = this.info.VerifiedSourceWarehouse;
        this.VerifiedDestinationWarehouse = this.info.VerifiedDestinationWarehouse;
        this.VerifiedArticleCodeOnDestinationDefaultWarehouse =
            this.info.VerifiedArticleCodeOnDestinationDefaultWarehouse;

        this.VerifiedArticleCodeTitle = String.format(
            TextResources.Warehouse.VerifiedArticleCodeTitle,
            this.info.VerifiedArticleCode
                ? TextResources.Warehouse.RightVerification
                : this.info.VerifiedArticleCode === false
                ? TextResources.Warehouse.WrongVerification
                : TextResources.Warehouse.VerificationNotExecute
        );
        this.VerifiedSourceWarehouseTitle = String.format(
            TextResources.Warehouse.VerifiedSourceWarehouseTitle,
            this.info.VerifiedSourceWarehouse
                ? TextResources.Warehouse.RightVerification
                : this.info.VerifiedSourceWarehouse === false
                ? TextResources.Warehouse.WrongVerification
                : TextResources.Warehouse.VerificationNotExecute
        );
        this.VerifiedDestinationWarehouseTitle = String.format(
            TextResources.Warehouse.VerifiedDestinationWarehouseTitle,
            this.info.VerifiedDestinationWarehouse
                ? TextResources.Warehouse.RightVerification
                : this.info.VerifiedDestinationWarehouse === false
                ? TextResources.Warehouse.WrongVerification
                : TextResources.Warehouse.VerificationNotExecute
        );
        this.VerifiedArticleCodeOnDestinationDefaultWarehouseTitle = String.format(
            TextResources.Warehouse.VerifiedArticleCodeOnDestinationDefaultWarehouseTitle,
            this.info.VerifiedArticleCodeOnDestinationDefaultWarehouse
                ? TextResources.Warehouse.RightVerification
                : this.info.VerifiedArticleCodeOnDestinationDefaultWarehouse === false
                ? TextResources.Warehouse.WrongVerification
                : TextResources.Warehouse.VerificationNotExecute
        );

        this.sources = this.originalSources.slice();

        this.RequestedAmount(this.sources.sum((s) => s.RefAmount) - (this.info.DestinationWarehouseStock ?? 0));
        this.createOperationSources();

        this.isChanged(0);
    }

    public onItemSelected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.WarehousesDataSource) this.setSourceWarehouseInfo(model?.model);
        else {
            const article = model?.model;
            this.setArticleInfo(article);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onItemDeselected(sender: IDataSource, model: IDataSourceModel): void {
        if (sender === this.WarehousesDataSource) this.clearSourceWarehouseInfo();
        else this.clearArticleInfo();
    }

    public async canSelectItem(sender: IDataSource, model: IDataSourceModel): Promise<boolean> {
        const warehouseSelection = sender === this.WarehousesDataSource;
        const articleSelection = sender === this.ArticlesDataSource;

        const articleId = articleSelection ? (model.model as IArticle).ArticleId : this.ArticleId();
        const sourceWarehouseId = warehouseSelection ? model.model.Id : this.SourceWarehouseId();
        const operationType =
            this.warehouse.DestinationWarehouseId === sourceWarehouseId
                ? WarehouseInspectionOperationType.StockCheck
                : WarehouseInspectionOperationType.WarehouseTransfer;

        const canSelect = !this.warehouse.operationAlreadyExists({
            FKSourceWarehouse: sourceWarehouseId,
            FKArticle: articleId,
            OperationType: operationType,
            Id: this.Id,
        } as IFullWarehouseInspectionWarehouseInspectionOperations);
        if (canSelect) return true;

        await this.dialogsService.AlertAsync(
            TextResources.Warehouse.DuplicatedWarehouseInspectionOperation,
            TextResources.Warehouse.DuplicatedWarehouseInspectionOperationLabel
        );
        return false;
    }

    public showAlert(alertMessage: string): void {
        this.Alert(true);
        this.AlertMessage(alertMessage);
    }

    public hideAlert(): void {
        this.Alert(false);
        this.AlertMessage("");
    }

    public merge(
        operation: IFullWarehouseInspectionWarehouseInspectionOperations,
        sources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ): void {
        for (const source of sources) {
            const existingSource = this.sources.firstOrDefault((s) => s.FKRef === source.FKRef);

            if (!existingSource) this.sources.push(source);
            else existingSource.RefAmount += source.RefAmount;
        }

        const requestedAmount = this.sources.sum((s) => s.RefAmount);
        this.RequestedAmount(requestedAmount - (operation.DestinationWarehouseStock ?? 0)); // TODO in questo modo si rischia di schiacciare l'input dell'utente, trovare una soluzione al problema
        this.createOperationSources();
    }

    public getData(): IFullWarehouseInspectionWarehouseInspectionOperations {
        const data = Object.assign({}, this.info) as IFullWarehouseInspectionWarehouseInspectionOperations;

        data.FKArticle = this.ArticleId();
        data.ArticleCode = this.ArticleCode;
        data.FKSourceWarehouse = this.SourceWarehouseId();
        data.FKDestinationWarehouse = this.warehouse.DestinationWarehouseId();
        data.JobOrderId = this.warehouse.JobOrderId;
        data.CustomerId = this.warehouse.CustomerId;
        data.RequestedAmount = this.RequestedAmount();
        data.MovementAmount = this.MovementAmount();
        data.ActualAmount = this.ActualAmount();
        data.Done = this.Done();
        data.OperationType = this.OperationType();
        data.VerifiedArticleCode = this.VerifiedArticleCode;
        data.VerifiedSourceWarehouse = this.VerifiedSourceWarehouse;
        data.VerifiedDestinationWarehouse = this.VerifiedDestinationWarehouse;
        data.VerifiedArticleCodeOnDestinationDefaultWarehouse = this.VerifiedArticleCodeOnDestinationDefaultWarehouse;

        return data;
    }

    public getSourcesData(): IFullWarehouseInspectionWarehouseInspectionOperationsSources[] {
        return this.sources.map((s) => {
            s.FKInspectionOperation = this.info.Id;
            return s;
        });
    }

    public setNewIdFromData(data: IFullWarehouseInspectionWarehouseInspectionOperations) {
        this.info.Id = data.Id;
    }

    public setRowSatus(status: boolean): void {
        this.Done(status);
    }

    public dispose(): void {
        // Nothing to do
    }

    public renderSourcesTable() {
        const operationSourcesInfo = this;
        let source: IDataSourceModel<number, OperationSourceInfo>;

        return (
            <With data={this} as="operationSourcesInfo">
                {() => (
                    <Table
                        dataSource={{ array: this.OperationsSources, factory: this.createSourceModel }}
                        compact={true}
                        rowAs="source">
                        <Column title={TextResources.Warehouse.InspectionOperationSourceDocumentColumnTitle}>
                            <ColumnBody>
                                {(item: ITableItem<OperationSourceInfo>) =>
                                    String.format(
                                        TextResources.Invoices.DocumentName,
                                        item.Data.model.DocumentLabel,
                                        item.Data.model.DocumentNumber,
                                        moment(item.Data.model.DocumentDate).format("L")
                                    )
                                }
                            </ColumnBody>
                        </Column>
                        <Column title={TextResources.Warehouse.InspectionOperationSourceDocumentRegisterColumnTitle}>
                            <ColumnBody>
                                {(item: ITableItem<OperationSourceInfo>) => (
                                    <span>{item.Data.model.VatRegisterName}</span>
                                )}
                            </ColumnBody>
                        </Column>
                        <Column
                            title={TextResources.Warehouse.InspectionOperationSourceDocumentAmountColumnTitle}
                            style={{ width: "120px" }}>
                            <span data-bind={{ numberText: source.model.Amount }}></span>
                        </Column>
                        <Column className="text-right" style={{ width: "40px" }}>
                            <button
                                type="button"
                                className="btn btn-success btn-xs"
                                data-bind={{
                                    asyncClick: operationSourcesInfo.showDocument.bind(operationSourcesInfo, source.id),
                                }}>
                                <i className="fa fa-search"></i>
                            </button>
                        </Column>
                    </Table>
                )}
            </With>
        );
    }

    private setArticleInfo(model: IArticle) {
        this.ArticleDescription = model?.Description;
        this.ArticleCode = model?.Code;
        this.MefCode = model?.MefCode;
        this.EanCode = model?.EanCode;
    }

    private setSourceWarehouseInfo(model: IWarehouseWithStockInfo) {
        this.SourceWarehouse = model?.Name;
        this.OperationType(
            model?.Id === this.warehouse.DestinationWarehouseId()
                ? WarehouseInspectionOperationType.StockCheck
                : WarehouseInspectionOperationType.WarehouseTransfer
        );
    }

    private clearSourceWarehouseInfo() {
        this.SourceWarehouse = null;
    }

    private clearArticleInfo() {
        this.ArticleDescription = null;
        this.ArticleCode = null;
        this.MefCode = null;
        this.EanCode = null;
    }

    private async showDocument(documentId): Promise<void> {
        return this.documentsService.OpenDocumentOverlayById(documentId);
    }

    private createSourceModel(source: OperationSourceInfo): IDataSourceModel<number, OperationSourceInfo> {
        return {
            id: source.DocumentId,
            title: source.DocumentLabel,
            isGroup: false,
            isLeaf: true,
            model: source,
        };
    }

    private createOperationSources(): void {
        const sourcesMap = {};

        for (const source of this.sources) {
            const sourceInfo = sourcesMap[source.FKDocument];
            if (!sourceInfo) sourcesMap[source.FKDocument] = this.createOperationSource(source);
            else sourceInfo.Amount += source.RefAmount;
        }

        const sources = [];
        for (const docId in sourcesMap) sources.push(sourcesMap[docId]);

        this.OperationsSources(sources);
    }

    private createOperationSource(
        source: IFullWarehouseInspectionWarehouseInspectionOperationsSources
    ): OperationSourceInfo {
        return {
            DocumentId: source.FKDocument,
            DocumentDate: source.DocumentDate,
            DocumentNumber: source.DocumentNumber,
            DocumentLabel: source.DocumentLabel,
            VatRegisterName: source.RegisterName,
            Amount: source.RefAmount,
        };
    }
}
