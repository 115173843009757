import * as ProlifeSdk from "../../../../ProlifeSdk/ProlifeSdk";
import * as React from "@abstraqt-dev/jsxknockout";
import { IWarehouseSection } from "../WarehouseViewModel";
import { BindTo } from "../../../../Components/Bind";
import { LayoutContent, LayoutWithHeader, LayoutHeader } from "../../../../Components/Layouts";
import {
    IFullWarehouseInspection,
    IFullWarehouseInspectionWarehouseInspectionOperations,
    IFullWarehouseInspectionWarehouseInspectionOperationsSources,
    IWarehouseInspectionsService,
    IWarehouseInspectionWizardArticleInfo,
} from "../../../WarehouseInspectionsService";
import { Portlet, PortletHeader, PortletBody, PortletActions } from "../../../../Components/Portlet";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { WarehouseInspectionStatus } from "./Enums/WarehouseInspectionStatus";
import { DetectClassChanges, DetectChanges } from "../../../../Core/ChangeDetection";
import { LazyImport } from "../../../../Core/DependencyInjection";
import { IDialogsService } from "../../../../Core/interfaces/IDialogsService";
import { IValidationException, IException } from "../../../../Core/interfaces/IException";
import { IInfoToastService } from "../../../../Core/interfaces/IInfoToastService";
import { IUserInfo } from "../../../../ProlifeSdk/interfaces/desktop/IUserInfo";
import { WarehouseArticlesImporterForInspection } from "./Wizard/WarehouseArticlesImporterForInspection";
import { WarehouseInspectionWarehousesInfoTable } from "./WarehouseInspectionWarehousesInfoTable";
import { WarehouseInspectionWarehouseInfo, WarehouseInspectionOperationInfo } from "./WarehouseInspectionWarehouseInfo";
import { FullWarehouseInspectionFactory } from "./FullWarehouseInspectionFactory";
import { If } from "../../../../Components/IfIfNotWith";
import { reloadNow } from "../../../../Core/utils/ComponentUtils";

interface IWarehouseInspectionStatus {
    Id: WarehouseInspectionStatus;
    Label: string;
}

export type WarehouseInspectionEditorProps = {
    fullInspection: IFullWarehouseInspection;

    hideSaveAndCloseButtons?: boolean;
    focusOnTitle?: boolean;

    onSaveSuccess?: () => void;
    onDeleteSuccess?: () => void;
    onEditingAbort?: () => void;

    ref?: (editor: _WarehouseInspectionEditor) => void;
};

export function WarehouseInspectionEditor(props: WarehouseInspectionEditorProps) {
    const C = require("./WarehouseInspectionEditor")._WarehouseInspectionEditor as typeof _WarehouseInspectionEditor;
    return <C {...props} />;
}

export class WarehouseInspectionEditorUI implements IWarehouseSection {
    public SectionId = 12;

    constructor(private props: WarehouseInspectionEditorProps) {}

    render() {
        return <WarehouseInspectionEditor {...this.props} />;
    }
}

@DetectClassChanges
export class _WarehouseInspectionEditor implements IWarehouseSection {
    public get Id(): number {
        return this.props.fullInspection?.WarehouseInspection?.firstOrDefault()?.Id;
    }

    public SectionId = 12;
    public IsNew: ko.Observable<boolean> = ko.observable(false);

    public StatusList: IWarehouseInspectionStatus[] = [];

    @DetectChanges
    public Title: ko.Observable<string> = ko.observable();
    @DetectChanges
    public Status: ko.Observable<WarehouseInspectionStatus> = ko.observable();
    @DetectChanges
    public Warehouses: ko.ObservableArray<WarehouseInspectionWarehouseInfo> = ko.observableArray([]);

    public isChanged: ko.Observable<number> = ko.observable(0);
    public IsDraft: ko.Observable<boolean> = ko.observable(false);

    public HasDocumentsGenerationError: ko.Observable<boolean> = ko.observable(false);
    public LastDocumentsGenerationInspectionError: ko.Observable<string> = ko.observable();
    public CanGenerateDocuments: ko.Observable<boolean> = ko.observable(false);

    public editorTitle: string;

    private ShouldRender: ko.Observable<boolean> = ko.observable(false);

    @LazyImport(nameof<IWarehouseInspectionsService>())
    private warehouseInspectionsService: IWarehouseInspectionsService;
    @LazyImport(nameof<IDialogsService>())
    private dialogsService: IDialogsService;
    @LazyImport(nameof<IInfoToastService>())
    private infoToastService: IInfoToastService;
    @LazyImport(nameof<IUserInfo>())
    private userInfoService: IUserInfo;

    private hideImportButton = false;

    constructor(public props: WarehouseInspectionEditorProps) {
        const inspectionData = this.props.fullInspection?.WarehouseInspection?.firstOrDefault();
        this.hideImportButton = !!inspectionData?.WorkedBy;

        this.IsNew(!this.props.fullInspection || (inspectionData?.Id ?? -1) <= 0);
        this.IsDraft(inspectionData?.Status === WarehouseInspectionStatus.Draft);

        this.editorTitle = this.IsNew()
            ? TextResources.Warehouse.NewInspectionTitle
            : TextResources.Warehouse.EditInspectionTitle;

        this.loadStatusList();

        this.Title(inspectionData?.Title);
        this.Status(inspectionData?.Status ?? WarehouseInspectionStatus.Draft);

        this.loadOperations();
        this.setDocumentsGenerationStatus();
        this.isChanged(0);

        this.ShouldRender(true);
    }

    public componentWillUnmount(): void {
        this.dispose();
    }

    public hasChanges(): boolean {
        return (
            this.isChanged() > 0 ||
            !!this.Warehouses().firstOrDefault(
                (a) => a.isChanged() > 0 || !!a.Operations().firstOrDefault((o) => o.isChanged() > 0)
            )
        );
    }

    public dispose(): void {
        // Nothing to do
    }

    public async save(): Promise<void> {
        const data = this.getData();
        const userId = this.userInfoService.getIdUser();

        try {
            const savedData = await this.warehouseInspectionsService.CreateOrUpdateWarehouseInspections(
                data.WarehouseInspection,
                data.WarehouseInspectionOperations,
                data.WarehouseInspectionOperationsSources,
                userId
            );
            this.props.fullInspection = savedData;

            const inspection = this.props.fullInspection.WarehouseInspection.firstOrDefault();
            this.IsDraft(inspection.Status === WarehouseInspectionStatus.Draft);

            this.resetChangesStatus();
            this.propagateIdsOnAddedOperations();
            this.setDocumentsGenerationStatus();

            this.infoToastService.Success(TextResources.Warehouse.WarehouseInspectionSaveSuccess);

            if (this.props.onSaveSuccess) this.props.onSaveSuccess();

            this.ShouldRender(false);
            this.ShouldRender(true);
        } catch (e) {
            const exception: IValidationException = e as IValidationException;

            if (exception.ExceptionType === ProlifeSdk.ServerException_ProLifeValidationException)
                this.infoToastService.Error(exception.ExceptionMessage);
        }
    }

    public async importArticles(): Promise<void> {
        const importer = new WarehouseArticlesImporterForInspection({ WarehouseInspectionId: this.Id });
        const importedArticles = await importer.show();
        this.dialogsService.LockUI(TextResources.Warehouse.InspectionOpearionsImportLoading, true);
        try {
            await this.createAndAppendOperations(importedArticles);
        } finally {
            this.dialogsService.UnlockUI();
        }
    }

    public async deleteInspection(): Promise<void> {
        if (this.IsNew()) return;

        const confirm: boolean = await this.dialogsService.ConfirmAsync(
            TextResources.Warehouse.DeleteInspectionMessage,
            TextResources.ProlifeSdk.Abort,
            TextResources.ProlifeSdk.Confirm
        );

        if (confirm) {
            try {
                const inspection = this.props.fullInspection.WarehouseInspection.firstOrDefault();
                await this.warehouseInspectionsService.DeleteWarehouseInspection(inspection.Id, null);

                this.resetChangesStatus();

                if (this.props.onDeleteSuccess) this.props.onDeleteSuccess();
            } catch (e) {
                const exception: IValidationException = e as IValidationException;
                if (exception.ExceptionType === ProlifeSdk.ServerException_ProLifeValidationException)
                    this.infoToastService.Error(exception.ExceptionMessage);
            }
        }
    }

    public addWarehouseInspection(): void {
        this.Warehouses.push(this.createWarehouseInspectionWarehouseInfo([], []));
    }

    public async deleteWarehouseInspection(warehouseInfo: WarehouseInspectionWarehouseInfo): Promise<void> {
        const ids = warehouseInfo
            .Operations()
            .filter((o) => o.Id > 0)
            .map((o) => o.Id);

        if (ids.length > 0) {
            const confirm = await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.ConfirmDeleteInspectionRows,
                TextResources.ProlifeSdk.No,
                TextResources.ProlifeSdk.Yes
            );

            if (!confirm) return;

            try {
                await this.warehouseInspectionsService.DeleteWarehouseInspectionRows(ids, null);

                this.Warehouses.remove(warehouseInfo);
            } catch (e) {
                return;
            }
        }
    }

    public addInspectionOperation(warehouseInfo: WarehouseInspectionWarehouseInfo): void {
        warehouseInfo.addOperation();
    }

    public async deleteInspectionOperation(
        operation: WarehouseInspectionOperationInfo,
        warehouseInfo: WarehouseInspectionWarehouseInfo
    ): Promise<void> {
        if (operation.Id > 0) {
            const confirm = await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.ConfirmDeleteInspectionRow,
                TextResources.ProlifeSdk.No,
                TextResources.ProlifeSdk.Yes
            );

            if (!confirm) return;

            try {
                await this.warehouseInspectionsService.DeleteWarehouseInspectionRows([operation.Id], null);
                this.removeInspectionOperation(operation, warehouseInfo);
            } catch (e) {
                return;
            }
        } else {
            this.removeInspectionOperation(operation, warehouseInfo);
        }
    }

    public async abort(): Promise<boolean> {
        let confirm = true;
        if (this.hasChanges())
            confirm = await this.dialogsService.ConfirmAsync(
                TextResources.Warehouse.InspectionPendingChangesMessage,
                TextResources.ProlifeSdk.Abort,
                TextResources.ProlifeSdk.Confirm
            );

        if (confirm) {
            if (this.props.onEditingAbort) this.props.onEditingAbort();
        }

        return confirm;
    }

    public render() {
        return (
            <BindTo viewModel={this} as="inspectionEditor">
                <LayoutWithHeader>
                    <LayoutHeader>{this.renderHeader()}</LayoutHeader>
                    <LayoutContent noOverflow={true}>
                        <If condition={this.ShouldRender}>
                            {() => (
                                <WarehouseInspectionWarehousesInfoTable
                                    operationsInfo={this.Warehouses}
                                    isDraft={this.IsDraft()}
                                    disableOperationsVariations={this.hideImportButton}
                                />
                            )}
                        </If>
                    </LayoutContent>
                </LayoutWithHeader>
            </BindTo>
        );
    }

    private setDocumentsGenerationStatus() {
        const inspectionData = this.props.fullInspection?.WarehouseInspection?.firstOrDefault();
        const hasGeneratedDocuments = this.props.fullInspection?.DocumentsGenerated?.DocumentsGenerated;
        this.LastDocumentsGenerationInspectionError(
            this.props.fullInspection?.LastDocumentsGenerationError?.LastDocumentsGenerationError
        );
        this.HasDocumentsGenerationError(this.LastDocumentsGenerationInspectionError() && !hasGeneratedDocuments);
        this.CanGenerateDocuments(
            inspectionData?.Status === WarehouseInspectionStatus.Closed && !hasGeneratedDocuments
        );
    }

    private renderHeader() {
        let inspectionEditor: _WarehouseInspectionEditor;

        return (
            <Portlet collapsible={false}>
                <PortletHeader>
                    <Portlet.Header.Default title={this.editorTitle} className="bold uppercase" />
                </PortletHeader>
                <PortletActions>
                    <button
                        type="button"
                        class="btn btn-danger btn-circle"
                        data-bind={{
                            asyncClick: inspectionEditor.deleteInspection.bind(inspectionEditor),
                            visible: !inspectionEditor.IsNew(),
                        }}
                    >
                        <i className="fa fa-trash-o"></i>&nbsp;
                        {TextResources.ProlifeSdk.Delete}
                    </button>
                    &nbsp;
                    {!this.props.hideSaveAndCloseButtons && (
                        <>
                            <button
                                type="button"
                                class="btn btn-default btn-circle"
                                data-bind={{ asyncClick: inspectionEditor.abort.bind(inspectionEditor) }}
                            >
                                <i className="fa fa-times"></i>&nbsp;
                                {TextResources.ProlifeSdk.Close}
                            </button>
                            &nbsp;
                        </>
                    )}
                    {!this.hideImportButton && (
                        <>
                            <button
                                type="button"
                                class="btn btn-success btn-circle"
                                data-bind={{ asyncClick: inspectionEditor.importArticles.bind(inspectionEditor) }}
                            >
                                <i className="fa fa-magic"></i>&nbsp;
                                {TextResources.ProlifeSdk.Import}
                            </button>
                            &nbsp;
                        </>
                    )}
                    {!this.props.hideSaveAndCloseButtons && (
                        <button
                            type="button"
                            class="btn btn-primary btn-circle"
                            data-bind={{ asyncClick: inspectionEditor.save.bind(inspectionEditor) }}
                        >
                            <i className="fa fa-floppy-o"></i>&nbsp;
                            {TextResources.ProlifeSdk.Save}
                        </button>
                    )}
                </PortletActions>
                <PortletBody>
                    {() => (
                        <>
                            <div className="row">
                                <div className="col-md-9">
                                    <text-input
                                        value={() => "inspectionEditor.Title"}
                                        hasFocus={!!this.props.focusOnTitle}
                                        label={TextResources.Warehouse.InspectionTitle}
                                        placeholder={TextResources.Warehouse.InspectionTitlePlaceholder}
                                        selectOnFocus={true}
                                    ></text-input>
                                </div>
                                <div className="col-md-3 form-group">
                                    <label className="control-label">{TextResources.Warehouse.InspectionStatus}</label>
                                    <select
                                        className="form-control"
                                        data-bind={{
                                            value: inspectionEditor.Status,
                                            options: inspectionEditor.StatusList,
                                            optionsText: "Label",
                                            optionsValue: "Id",
                                        }}
                                    ></select>
                                </div>
                            </div>
                            <If condition={this.HasDocumentsGenerationError}>
                                {() => (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="alert alert-danger">
                                                <strong>
                                                    {TextResources.Warehouse.InspectionDocumentsGenerationErrorMessage}
                                                </strong>{" "}
                                                <ko-bind
                                                    data-bind={{
                                                        text: inspectionEditor.LastDocumentsGenerationInspectionError,
                                                    }}
                                                ></ko-bind>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </If>
                            <If condition={this.CanGenerateDocuments}>
                                {() => (
                                    <div className="row">
                                        <div className="col-md-12 text-right" style={{ marginTop: "10px;" }}>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                data-bind={{
                                                    asyncClick:
                                                        inspectionEditor.generateDocuments.bind(inspectionEditor),
                                                }}
                                            >
                                                {TextResources.Warehouse.GenerateWarehouseInspectionDocuments}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </If>
                        </>
                    )}
                </PortletBody>
            </Portlet>
        );
    }

    private removeInspectionOperation(
        operation: WarehouseInspectionOperationInfo,
        warehouseInfo: WarehouseInspectionWarehouseInfo
    ): void {
        warehouseInfo.Operations.remove(operation);
        if (warehouseInfo.Operations().length === 0) this.Warehouses.remove(warehouseInfo);
    }

    private async generateDocuments(): Promise<void> {
        if (!this.Id || this.Id <= 0) return;

        try {
            await this.warehouseInspectionsService.GenerateDocuments(this.Id);
            this.infoToastService.Success(TextResources.Warehouse.InspectionDocumentsGenerationSuccessMessage);

            this.HasDocumentsGenerationError(false);
            this.CanGenerateDocuments(false);
        } catch (e) {
            const ex = e as IException;
            this.LastDocumentsGenerationInspectionError(ex.Message);
        }
    }

    private propagateIdsOnAddedOperations(): void {
        for (const warehouse of this.Warehouses()) {
            for (const operation of warehouse.Operations()) {
                if (operation.Id > 0) continue;
                const data = this.props.fullInspection.WarehouseInspectionOperations.firstOrDefault(
                    (o) =>
                        o.FKArticle === operation.ArticleId() &&
                        o.FKSourceWarehouse === operation.SourceWarehouseId() &&
                        o.FKDestinationWarehouse === warehouse.DestinationWarehouseId() &&
                        o.OperationType === operation.OperationType()
                );
                if (!data) continue;

                operation.setNewIdFromData(data);
            }
        }
    }

    private loadStatusList(): void {
        this.StatusList.push({
            Id: WarehouseInspectionStatus.Draft,
            Label: TextResources.Warehouse.WarehouseInspectionDraftStatus,
        });
        this.StatusList.push({
            Id: WarehouseInspectionStatus.Workable,
            Label: TextResources.Warehouse.WarehouseInspectionWorkableStatus,
        });
        this.StatusList.push({
            Id: WarehouseInspectionStatus.Closed,
            Label: TextResources.Warehouse.WarehouseInspectionClosedStatus,
        });
    }

    private loadOperations(): void {
        let lastWarehouse: WarehouseInspectionWarehouseInfo;
        const warehouses: WarehouseInspectionWarehouseInfo[] = [];

        const operations = this.props.fullInspection?.WarehouseInspectionOperations ?? [];
        const operationsSources = (this.props.fullInspection?.WarehouseInspectionOperationsSources ?? []).filter(
            (s) => !!operations.firstOrDefault((o) => o.Id === s.FKInspectionOperation)
        );

        for (const operation of operations) {
            if (!lastWarehouse || lastWarehouse.DestinationWarehouseId() !== operation.FKDestinationWarehouse) {
                lastWarehouse = this.createWarehouseInspectionWarehouseInfo(
                    operations.filter((o) => o.FKDestinationWarehouse === operation.FKDestinationWarehouse),
                    operationsSources
                );
                warehouses.push(lastWarehouse);
            }
        }

        this.Warehouses(warehouses);
    }

    private async createAndAppendOperations(operations: IWarehouseInspectionWizardArticleInfo[]): Promise<void> {
        const actualWarehouses = this.Warehouses();

        const newWarehouses: WarehouseInspectionWarehouseInfo[] = [];
        let lastOperation: IWarehouseInspectionWizardArticleInfo = null;
        let groupedOperations: IWarehouseInspectionWizardArticleInfo[] = [];

        for (const operation of operations) {
            if (!lastOperation || lastOperation.DestinationWarehouseId !== operation.DestinationWarehouseId) {
                this.createWarehouseOperationsIfNeeded(
                    actualWarehouses,
                    groupedOperations,
                    lastOperation?.DestinationWarehouseId,
                    newWarehouses
                );
                groupedOperations = [];
            }

            groupedOperations.push(operation);
            lastOperation = operation;
        }

        this.createWarehouseOperationsIfNeeded(
            actualWarehouses,
            groupedOperations,
            lastOperation?.DestinationWarehouseId,
            newWarehouses
        );
        this.Warehouses(actualWarehouses.concat(newWarehouses));

        try {
            await this.save();
        } catch (e) {
            await this.dialogsService.AlertAsync(
                TextResources.Warehouse.WarehouseInspectionImportError,
                TextResources.Warehouse.WarehouseInspectionImportErrorLabel
            );
        }
    }

    private createWarehouseOperationsIfNeeded(
        actualWarehouses: WarehouseInspectionWarehouseInfo[],
        groupedOperations: IWarehouseInspectionWizardArticleInfo[],
        lastOperationDestinationWarehouseId: number,
        newWarehouses: WarehouseInspectionWarehouseInfo[]
    ): void {
        if (groupedOperations.length === 0) return;

        const warehouse = this.createOrUpdateWarehouseInfo(
            actualWarehouses,
            groupedOperations,
            lastOperationDestinationWarehouseId
        );
        if (!actualWarehouses.firstOrDefault((w) => w.DestinationWarehouseId === warehouse.DestinationWarehouseId))
            newWarehouses.push(warehouse);
    }

    private createOrUpdateWarehouseInfo(
        actualWarehouses: WarehouseInspectionWarehouseInfo[],
        operations: IWarehouseInspectionWizardArticleInfo[],
        destinationWarehouseId: number
    ): WarehouseInspectionWarehouseInfo {
        const actualWarehouse = actualWarehouses.firstOrDefault(
            (a) => a.DestinationWarehouseId() === destinationWarehouseId
        );
        const fullWarehouseInspectionOperations = operations.map((o) =>
            FullWarehouseInspectionFactory.toFullWarehouseInspectionOperationInfo(o)
        );
        const fullWarehouseInspectionOperationsSources = operations
            .selectMultiple((o) => o.Sources)
            .map((s) => FullWarehouseInspectionFactory.toFullWarehouseInspectionOperationSource(s));

        if (!actualWarehouse) {
            return this.createWarehouseInspectionWarehouseInfo(
                fullWarehouseInspectionOperations,
                fullWarehouseInspectionOperationsSources
            );
        } else {
            actualWarehouse.mergeOperationsInfo(
                fullWarehouseInspectionOperations,
                fullWarehouseInspectionOperationsSources
            );
            return actualWarehouse;
        }
    }

    private getData(): IFullWarehouseInspection {
        const data = Object.assign({}, this.props.fullInspection ?? {}) as IFullWarehouseInspection;

        const inspection = data.WarehouseInspection.firstOrDefault();
        inspection.Title = this.Title();
        inspection.Status = this.Status();

        if (inspection.AutomaticDocumentGeneration === null || inspection.AutomaticDocumentGeneration === undefined)
            inspection.AutomaticDocumentGeneration = true;

        const warehouses = this.Warehouses();

        data.WarehouseInspectionOperations = warehouses
            .selectMultiple((o) => o.getData())
            .map((w) => ({ ...w, FKInspection: inspection.Id }));

        data.WarehouseInspectionOperationsSources = warehouses.selectMultiple((o) => o.getSourcesData());

        return data;
    }

    private resetChangesStatus(): void {
        this.isChanged(0);

        for (const article of this.Warehouses()) {
            for (const operation of article.Operations()) operation.isChanged(0);
        }
    }

    private createWarehouseInspectionWarehouseInfo(
        operationsInfo: IFullWarehouseInspectionWarehouseInspectionOperations[],
        operationsSources: IFullWarehouseInspectionWarehouseInspectionOperationsSources[]
    ): WarehouseInspectionWarehouseInfo {
        return new WarehouseInspectionWarehouseInfo(operationsInfo, operationsSources);
    }
}

if (module.hot) {
    module.hot.accept();
    //module.hot.dispose(() => styleSheet.detach());
    reloadNow(WarehouseInspectionEditor);
}
