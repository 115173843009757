import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import jss from "jss";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { Column, ColumnBody, ColumnHeader } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { WarehouseInspectionOperationInfo, WarehouseInspectionWarehouseInfo } from "./WarehouseInspectionWarehouseInfo";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { Delay } from "../../../../Decorators/Delay";
import { WarehouseInspectionAction } from "./Enums/WarehouseInspectionAction";
import { _WarehouseInspectionEditor } from "./WarehouseInspectionEditor";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { Select2 } from "../../../../Components/Select2Component";
import { If, IfNot } from "../../../../Components/IfIfNotWith";
import { NumberInput } from "../../../../Components/NumberInput";

const { classes } = jss
    .createStyleSheet({
        inspectionOperations: {
            "&.operations-info": {
                "& .action-col": {
                    width: "80px",
                },
            },

            "& .op-status-col": {
                width: "90px",
            },

            "& .codes-verification": {
                width: "100px",

                "& .outcomes-wrapper": {
                    display: "inline-block",

                    "& span": {
                        display: "inline-block",
                        width: "15px",
                    },
                },
            },
        },
    })
    .attach();

export interface IInspectionOperationInfoProps {
    operations: ko.ObservableArray<WarehouseInspectionOperationInfo>;
    factory: (
        operation: WarehouseInspectionOperationInfo
    ) => IDataSourceModel<number, WarehouseInspectionOperationInfo>;

    isDraft?: boolean;
    disableOperationsVariations?: boolean;
}

export class InspectionOperationsInfo {
    private AllOperations: ko.ObservableArray<WarehouseInspectionOperationInfo> = ko.observableArray([]);
    private FilterdOperations: ko.ObservableArray<WarehouseInspectionOperationInfo> = ko.observableArray([]);

    private selectedArticles: number[] = [];
    private selectedWarehouses: number[] = [];
    private selectedOperationTypes: WarehouseInspectionAction[] = [];

    private subscriptions: ko.Subscription[] = [];

    constructor(public props: IInspectionOperationInfoProps) {
        this.AllOperations = this.props.operations;
        this.FilterdOperations(this.AllOperations());
    }

    componentDidMount() {
        const sub = this.AllOperations.subscribe(() => this.applyFilters());
        this.subscriptions.push(sub);
    }

    componentWillUnmount() {
        for (const sub of this.subscriptions) {
            sub.dispose();
        }

        this.subscriptions = [];
    }

    render() {
        let inspectionEditor: _WarehouseInspectionEditor;
        let warehouseInfo: IDataSourceModel<number, WarehouseInspectionWarehouseInfo>;
        let operationInfo: IDataSourceModel<number, WarehouseInspectionOperationInfo>;

        const { sortString, sortNumber } = ComponentUtils.useSorter<WarehouseInspectionOperationInfo>();
        const { getKey, getLabel } = ComponentUtils.useGetter<WarehouseInspectionOperationInfo, number>();

        return (
            <Table
                compact={true}
                fixedLayout={true}
                dataSource={{ array: this.FilterdOperations, factory: this.props.factory }}
                rowAs="operationInfo"
                className={classes.inspectionOperations + " operations-info"}
            >
                <Column sorter={sortString((m) => m.ArticleCode)}>
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Warehouse.Article}</span>
                                <TableFilter
                                    filterSource={this.AllOperations}
                                    itemLabelGetter={getLabel(
                                        getLabel((i) => i.ArticleCode + " - " + i.ArticleDescription)
                                    )}
                                    itemKeyGetter={getKey((i) => i.ArticleId())}
                                    onSelectionChange={this.onArticlesSelectionChanges.bind(this)}
                                ></TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WarehouseInspectionOperationInfo>) => (
                            <Select2
                                value={item.Data.model.ArticleId}
                                dataSource={item.Data.model.ArticlesDataSource}
                                listener={item.Data.model}
                                placeholder={TextResources.Warehouse.InspectionArticlePlaceholder}
                                readonly={this.props.disableOperationsVariations}
                                allowClear
                                simple
                            />
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    sorter={sortString((m) =>
                        m.OperationType() === 1
                            ? TextResources.Warehouse.StockCheck
                            : TextResources.Warehouse.WarehouseTransfer
                    )}
                >
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Warehouse.InspectonOperation}</span>
                                <TableFilter
                                    filterSource={this.AllOperations}
                                    itemLabelGetter={getLabel(
                                        getLabel((i) =>
                                            i.OperationType() === 1
                                                ? TextResources.Warehouse.StockCheck
                                                : TextResources.Warehouse.WarehouseTransfer
                                        )
                                    )}
                                    itemKeyGetter={getKey((i) => i.OperationType())}
                                    onSelectionChange={this.onOperationTypesSelectionChanges.bind(this)}
                                ></TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ko-bind data-bind={{ if: operationInfo.model.OperationType() === 1 }}>
                        <span>{TextResources.Warehouse.StockCheck}</span>
                    </ko-bind>
                    <ko-bind data-bind={{ if: operationInfo.model.OperationType() === 0 }}>
                        <span>{TextResources.Warehouse.WarehouseTransfer}</span>
                    </ko-bind>
                </Column>
                <Column sorter={sortString((m) => (m.OperationType() === 1 ? "N/A" : m.SourceWarehouse))}>
                    <ColumnHeader>
                        {() => (
                            <>
                                <span>{TextResources.Warehouse.SourceWarehouseColumn}</span>
                                <TableFilter
                                    filterSource={this.AllOperations}
                                    itemLabelGetter={getLabel(
                                        getLabel((i) =>
                                            i.OperationType() === 1
                                                ? TextResources.ProlifeSdk.EmptyTableFilterItem
                                                : i.SourceWarehouse
                                        )
                                    )}
                                    itemKeyGetter={getKey((i) => i.SourceWarehouseId())}
                                    onSelectionChange={this.onSourceWarehousesSelectionChanges.bind(this)}
                                ></TableFilter>
                            </>
                        )}
                    </ColumnHeader>
                    <ColumnBody>
                        {(item: ITableItem<WarehouseInspectionOperationInfo>) => (
                            <>
                                <IfNot condition={() => item.Data.model.OperationType() === 1}>
                                    {() => (
                                        <Select2
                                            value={item.Data.model.SourceWarehouseId}
                                            dataSource={item.Data.model.WarehousesDataSource}
                                            listener={item.Data.model}
                                            placeholder={TextResources.Warehouse.InspectionSourceWarehousePlaceholder}
                                            readonly={this.props.disableOperationsVariations}
                                            allowClear
                                            simple
                                        />
                                    )}
                                </IfNot>
                                <If condition={() => item.Data.model.OperationType() === 1}>
                                    {() => <span>{TextResources.ProlifeSdk.NotAvailable}</span>}
                                </If>
                            </>
                        )}
                    </ColumnBody>
                </Column>
                <Column
                    title={TextResources.Warehouse.ArticleStockColumn}
                    tooltip={TextResources.Warehouse.ArticleStockColumnTitle}
                    sorter={sortNumber((m) => m.ArticleStock())}
                    className="text-right"
                    style={{ width: "120px" }}
                >
                    <span data-bind={{ numberText: operationInfo.model.ArticleStock, textIfNoValue: "N/A" }}></span>
                </Column>
                <Column
                    title={TextResources.Warehouse.RequestedAmountExt}
                    sorter={sortNumber((m) => (m.OperationType() === 0 ? m.RequestedAmount() : 0))}
                    style={{ width: "120px" }}
                >
                    <ColumnBody>
                        {(item: ITableItem<WarehouseInspectionOperationInfo>) => (
                            <>
                                {/* Mostro il campo quantità solo se è un trasferimento di magazzino */}
                                <If condition={() => item.Data.model.OperationType() === 0}>
                                    {() => (
                                        <NumberInput
                                            value={item.Data.model.RequestedAmount}
                                            selectOnFocus={true}
                                            placeholder={TextResources.Warehouse.Amount}
                                            format="0,0"
                                            simple
                                        />
                                    )}
                                </If>
                                <IfNot condition={() => item.Data.model.OperationType() === 0}>
                                    {() => <span>{TextResources.ProlifeSdk.NotAvailable}</span>}
                                </IfNot>
                            </>
                        )}
                    </ColumnBody>
                </Column>
                {!this.props.isDraft && (
                    <Column
                        title={TextResources.Warehouse.MovementAmountColumn}
                        sorter={sortNumber((m) => (m.OperationType() === 0 ? m.MovementAmount() : 0))}
                        style={{ width: "135px" }}
                    >
                        <ColumnBody>
                            {(item: ITableItem<WarehouseInspectionOperationInfo>) => (
                                <>
                                    {/* Mostro il campo quantità movimentata solo se è un trasferimento di magazzino */}
                                    <If condition={() => item.Data.model.OperationType() === 0}>
                                        {() => (
                                            <NumberInput
                                                value={item.Data.model.MovementAmount}
                                                selectOnFocus={true}
                                                placeholder={TextResources.Warehouse.MovementAmountColumn}
                                                format="0,0"
                                                nullable
                                                simple
                                            />
                                        )}
                                    </If>
                                    <IfNot condition={() => item.Data.model.OperationType() === 0}>
                                        {() => <span>{TextResources.ProlifeSdk.NotAvailable}</span>}
                                    </IfNot>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                )}
                {!this.props.isDraft && (
                    <Column
                        title={TextResources.Warehouse.ActualAmountColumn}
                        tooltip={TextResources.Warehouse.ActualAmountColumnTitle}
                        sorter={sortNumber((m) => m.ActualAmount())}
                        style={{ width: "120px" }}
                    >
                        <ColumnBody>
                            {(item: ITableItem<WarehouseInspectionOperationInfo>) => (
                                <>
                                    {/* Mostro il campo della quantità verificata solo se è una verifica di magazzino */}
                                    <If condition={() => item.Data.model.OperationType() === 1}>
                                        {() => (
                                            <NumberInput
                                                value={item.Data.model.ActualAmount}
                                                selectOnFocus={true}
                                                placeholder={TextResources.Warehouse.ActualAmountColumn}
                                                format="0,0"
                                                nullable
                                                simple
                                            />
                                        )}
                                    </If>
                                    <IfNot condition={() => item.Data.model.OperationType() === 1}>
                                        {() => <span>{TextResources.ProlifeSdk.NotAvailable}</span>}
                                    </IfNot>
                                </>
                            )}
                        </ColumnBody>
                    </Column>
                )}
                {!this.props.isDraft && (
                    <Column
                        title={TextResources.Warehouse.OperationStatusColumn}
                        className="op-status-col text-center"
                        sorter={sortNumber((m) => (m.Done() === true ? 2 : m.Done() === false ? 1 : 0))}
                    >
                        <button
                            type="button"
                            className="btn btn-xs"
                            data-bind={{
                                click: operationInfo.model.setRowSatus.bind(operationInfo.model, null),
                                css: {
                                    "btn-primary":
                                        operationInfo.model.Done() === null || operationInfo.model.Done() === undefined,
                                },
                            }}
                        >
                            <i className="fa fa-clock-o"></i>
                        </button>
                        <button
                            type="button"
                            className="btn btn-xs"
                            data-bind={{
                                click: operationInfo.model.setRowSatus.bind(operationInfo.model, false),
                                css: { "btn-primary": operationInfo.model.Done() === false },
                            }}
                        >
                            <i className="fa fa-times"></i>
                        </button>
                        <button
                            type="button"
                            className="btn btn-xs"
                            data-bind={{
                                click: operationInfo.model.setRowSatus.bind(operationInfo.model, true),
                                css: { "btn-primary": operationInfo.model.Done() === true },
                            }}
                        >
                            <i className="fa fa-check"></i>
                        </button>
                    </Column>
                )}
                {!this.props.isDraft && (
                    <Column title={TextResources.Warehouse.CodesVerificationStatus} className="codes-verification">
                        <span className="outcomes-wrapper">
                            <span data-bind={{ attr: { title: operationInfo.model.VerifiedArticleCodeTitle } }}>
                                <i
                                    className="fa"
                                    data-bind={{
                                        css: {
                                            "fa-check": operationInfo.model.VerifiedArticleCode,
                                            "fa-times": operationInfo.model.VerifiedArticleCode === false,
                                            "fa-clock-o":
                                                operationInfo.model.VerifiedArticleCode === null ||
                                                operationInfo.model.VerifiedArticleCode === undefined,
                                        },
                                        style: {
                                            color:
                                                operationInfo.model.VerifiedArticleCode === null ||
                                                operationInfo.model.VerifiedArticleCode === undefined
                                                    ? "#ddd"
                                                    : "black",
                                        },
                                    }}
                                ></i>
                            </span>
                            <span
                                data-bind={{
                                    attr: {
                                        title: operationInfo.model
                                            .VerifiedArticleCodeOnDestinationDefaultWarehouseTitle,
                                    },
                                }}
                            >
                                <i
                                    className="fa"
                                    data-bind={{
                                        css: {
                                            "fa-check":
                                                operationInfo.model.VerifiedArticleCodeOnDestinationDefaultWarehouse,
                                            "fa-times":
                                                operationInfo.model.VerifiedArticleCodeOnDestinationDefaultWarehouse ===
                                                false,
                                            "fa-clock-o":
                                                operationInfo.model.VerifiedArticleCodeOnDestinationDefaultWarehouse ===
                                                    null ||
                                                operationInfo.model.VerifiedArticleCodeOnDestinationDefaultWarehouse ===
                                                    undefined,
                                        },
                                        style: {
                                            color:
                                                operationInfo.model.VerifiedArticleCodeOnDestinationDefaultWarehouse ===
                                                    null ||
                                                operationInfo.model.VerifiedArticleCodeOnDestinationDefaultWarehouse ===
                                                    undefined
                                                    ? "#ddd"
                                                    : "black",
                                        },
                                    }}
                                ></i>
                            </span>
                            <span
                                data-bind={{ attr: { title: operationInfo.model.VerifiedDestinationWarehouseTitle } }}
                            >
                                <i
                                    className="fa"
                                    data-bind={{
                                        css: {
                                            "fa-check": operationInfo.model.VerifiedDestinationWarehouse,
                                            "fa-times": operationInfo.model.VerifiedDestinationWarehouse === false,
                                            "fa-clock-o":
                                                operationInfo.model.VerifiedDestinationWarehouse === null ||
                                                operationInfo.model.VerifiedDestinationWarehouse === undefined,
                                        },
                                        style: {
                                            color:
                                                operationInfo.model.VerifiedDestinationWarehouse === null ||
                                                operationInfo.model.VerifiedDestinationWarehouse === undefined
                                                    ? "#ddd"
                                                    : "black",
                                        },
                                    }}
                                ></i>
                            </span>
                            <span data-bind={{ attr: { title: operationInfo.model.VerifiedSourceWarehouseTitle } }}>
                                <i
                                    className="fa"
                                    data-bind={{
                                        css: {
                                            "fa-check": operationInfo.model.VerifiedSourceWarehouse,
                                            "fa-times": operationInfo.model.VerifiedSourceWarehouse === false,
                                            "fa-clock-o":
                                                operationInfo.model.VerifiedSourceWarehouse === null ||
                                                operationInfo.model.Done === undefined,
                                        },
                                        style: {
                                            color:
                                                operationInfo.model.VerifiedSourceWarehouse === null ||
                                                operationInfo.model.VerifiedSourceWarehouse === undefined
                                                    ? "#ddd"
                                                    : "black",
                                        },
                                    }}
                                ></i>
                            </span>
                        </span>
                    </Column>
                )}
                {!this.props.isDraft && (
                    <Column className="alert-col text-center">
                        <span
                            data-bind={{
                                visible: operationInfo.model.Alert,
                                attr: { title: operationInfo.model.AlertMessage },
                            }}
                        >
                            <i class="fa fa-exclamation-triangle"></i>
                        </span>
                    </Column>
                )}
                <Column className="action-col text-right">
                    {!this.props.disableOperationsVariations && (
                        <ColumnHeader>
                            <button
                                type="button"
                                className="btn btn-primary btn-xs"
                                style={{ marginBottom: "3px" }}
                                data-bind={{
                                    click: inspectionEditor.addInspectionOperation.bind(
                                        inspectionEditor,
                                        warehouseInfo.model
                                    ),
                                }}
                            >
                                <i className="fa fa-plus"></i>
                            </button>
                        </ColumnHeader>
                    )}
                    <button
                        type="button"
                        className="btn btn-xs"
                        data-bind={{ toggle: operationInfo.model.ShowSources }}
                    >
                        <i
                            className="fa"
                            data-bind={{
                                css: {
                                    "fa-chevron-down": !operationInfo.model.ShowSources(),
                                    "fa-chevron-up": operationInfo.model.ShowSources,
                                },
                            }}
                        ></i>
                    </button>
                    {!this.props.disableOperationsVariations && (
                        <button
                            type="button"
                            className="btn btn-danger btn-xs"
                            data-bind={{
                                asyncClick: inspectionEditor.deleteInspectionOperation.bind(
                                    inspectionEditor,
                                    operationInfo.model,
                                    warehouseInfo.model
                                ),
                            }}
                        >
                            <i className="fa fa-trash-o"></i>
                        </button>
                    )}
                </Column>
                <SecondaryRow if={() => "operationInfo.model.ShowSources"}>
                    {(item: ITableItem<WarehouseInspectionOperationInfo>) => (
                        <td
                            colSpan={this.props.isDraft ? 5 : 10}
                            style={{ paddingLeft: "20px", backgroundColor: "#f9f9f9" }}
                        >
                            {item.Data.model.renderSourcesTable()}
                        </td>
                    )}
                </SecondaryRow>
            </Table>
        );
    }

    private onArticlesSelectionChanges(articles: number[]): void {
        this.selectedArticles = articles;
        this.applyFilters();
    }

    private onSourceWarehousesSelectionChanges(warehouses: number[]): void {
        this.selectedWarehouses = warehouses;
        this.applyFilters();
    }

    private onOperationTypesSelectionChanges(operationTypes: WarehouseInspectionAction[]): void {
        this.selectedOperationTypes = operationTypes;
        this.applyFilters();
    }

    @Delay(100)
    private applyFilters(): void {
        const allItems = this.AllOperations();
        const filteredItems = [];

        for (const item of allItems) {
            if (
                this.selectedArticles.indexOf(item.ArticleId()) >= 0 &&
                this.selectedWarehouses.indexOf(item.SourceWarehouseId()) >= 0 &&
                this.selectedOperationTypes.indexOf(item.OperationType()) >= 0
            )
                filteredItems.push(item);
        }

        this.FilterdOperations(filteredItems);
    }
}
