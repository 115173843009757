import * as React from "@abstraqt-dev/jsxknockout";
import ko = require("knockout");
import jss from "jss";
import { With } from "../../../../Components/IfIfNotWith";
import { Table, ITableItem } from "../../../../Components/TableComponent/TableComponent";
import { IDataSourceModel } from "../../../../DataSources/IDataSource";
import { Column, ColumnHeader, ColumnBody } from "../../../../Components/TableComponent/CustomColumn";
import { TextResources } from "../../../../ProlifeSdk/ProlifeTextResources";
import { SecondaryRow } from "../../../../Components/TableComponent/SecondaryRow";
import { InspectionOperationsInfo } from "./InspectionOperationsInfo";
import { WarehouseInspectionWarehouseInfo, WarehouseInspectionOperationInfo } from "./WarehouseInspectionWarehouseInfo";
import { ComponentUtils } from "../../../../Core/utils/ComponentUtils";
import { TableFilter } from "../../../../Components/TableComponent/TableFilter";
import { Delay } from "../../../../Decorators/Delay";
import { _WarehouseInspectionEditor } from "./WarehouseInspectionEditor";
import { Select2 } from "../../../../Components/Select2Component";

const { classes } = jss
    .createStyleSheet({
        inspectionOperations: {
            "& .checkbox-col": {
                width: "30px",
            },

            "& .warehouse-col": {
                width: "30%",
            },

            "& .operations-number-col": {
                width: "140px",
            },

            "& .alert-col": {
                width: "60px",
            },

            "& .action-col": {
                width: "80px",
            },
        },
    })
    .attach();

export interface IWarehouseInspectionArticlesInfoTableProps {
    operationsInfo: ko.ObservableArray<WarehouseInspectionWarehouseInfo>;
    disableOperationsVariations?: boolean;
    isDraft?: boolean;
}

export class WarehouseInspectionWarehousesInfoTable {
    public OperationsInfo: ko.ObservableArray<WarehouseInspectionWarehouseInfo> = ko.observableArray([]);
    public FilteredOperationsInfo: ko.ObservableArray<WarehouseInspectionWarehouseInfo> = ko.observableArray([]);

    private subscriptions: ko.Subscription[] = [];
    private selectedWarehouses: number[] = [];
    private selectedCustomers: number[] = [];
    private selectedJobOrders: number[] = [];

    constructor(private props: IWarehouseInspectionArticlesInfoTableProps) {
        this.OperationsInfo = this.props.operationsInfo;
        this.FilteredOperationsInfo(this.OperationsInfo());
    }

    public componentDidMount(): void {
        this.subscriptions.push(
            this.OperationsInfo.subscribe(() => {
                this.applyFilters();
            })
        );
    }

    public componentWillUnmount(): void {
        for (const sub of this.subscriptions) sub.dispose();
    }

    public render() {
        let warehouseInfo: IDataSourceModel<number, WarehouseInspectionWarehouseInfo>;
        let inspectionEditor: _WarehouseInspectionEditor;

        const { sortString, sortNumber } = ComponentUtils.useSorter<WarehouseInspectionWarehouseInfo>();
        const { getKey, getLabel } = ComponentUtils.useGetter<WarehouseInspectionWarehouseInfo, number>();

        return (
            <With data={this} as="inspectionsOperationsInfoTable">
                {() => (
                    <Table
                        compact={true}
                        scrollable={true}
                        striped={true}
                        fixedLayout={true}
                        dataSource={{ array: this.FilteredOperationsInfo, factory: this.articleFactory.bind(this) }}
                        rowAs="warehouseInfo"
                        className={classes.inspectionOperations}
                    >
                        <Column sorter={sortString((m) => m.DestinationWarehouse)}>
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Warehouse.Warehouse}</span>
                                        <TableFilter
                                            filterSource={this.OperationsInfo}
                                            itemLabelGetter={getLabel((i) => i.DestinationWarehouse)}
                                            itemKeyGetter={getKey((i) => i.DestinationWarehouseId())}
                                            onSelectionChange={this.onWarehousesSelectionChanges.bind(this)}
                                        ></TableFilter>
                                    </>
                                )}
                            </ColumnHeader>
                            <ColumnBody>
                                {(item: ITableItem<WarehouseInspectionWarehouseInfo>) => (
                                    <Select2
                                        value={item.Data.model.DestinationWarehouseId}
                                        dataSource={item.Data.model.WarehousesDataSource}
                                        listener={item.Data.model}
                                        placeholder={TextResources.Warehouse.InspectionDestinationWarehousePlaceholder}
                                        readonly={this.props.disableOperationsVariations}
                                        allowClear
                                        simple
                                    />
                                )}
                            </ColumnBody>
                            <span data-bind={{ text: warehouseInfo.model.DestinationWarehouse }}></span>
                        </Column>
                        <Column
                            title={TextResources.Warehouse.OperationsNumber}
                            className="operations-number-col text-right"
                            sorter={sortNumber((m) => m.OperationsNumber())}
                        >
                            <span>
                                <span
                                    data-bind={{ numberText: warehouseInfo.model.OperationsNumber, format: "0,0" }}
                                ></span>
                            </span>
                        </Column>
                        <Column sorter={sortString((m) => m.CustomerName())}>
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Warehouse.Customer}</span>
                                        <TableFilter
                                            filterSource={this.OperationsInfo}
                                            itemLabelGetter={getLabel((i) => i.CustomerName())}
                                            itemKeyGetter={getKey((i) => i.CustomerId)}
                                            onSelectionChange={this.onCustomersSelectionChanges.bind(this)}
                                        ></TableFilter>
                                    </>
                                )}
                            </ColumnHeader>
                            <span
                                data-bind={{
                                    text: !warehouseInfo.model.CustomerName ? "N/A" : warehouseInfo.model.CustomerName,
                                }}
                            ></span>
                        </Column>
                        <Column sorter={sortString((m) => m.JobOrderName())}>
                            <ColumnHeader>
                                {() => (
                                    <>
                                        <span>{TextResources.Warehouse.JobOrder}</span>
                                        <TableFilter
                                            filterSource={this.OperationsInfo}
                                            itemLabelGetter={getLabel((i) => i.JobOrderName())}
                                            itemKeyGetter={getKey((i) => i.JobOrderId)}
                                            onSelectionChange={this.onJobOrdersSelectionChanges.bind(this)}
                                        ></TableFilter>
                                    </>
                                )}
                            </ColumnHeader>
                            <span
                                data-bind={{
                                    text: !warehouseInfo.model.JobOrderName ? "N/A" : warehouseInfo.model.JobOrderName,
                                }}
                            ></span>
                        </Column>
                        {!this.props.isDraft && (
                            <Column className="alert-col text-center">
                                <span data-bind={{ visible: warehouseInfo.model.OperationsProblemsAlert }}>
                                    <i
                                        class="fa fa-exclamation-triangle"
                                        title={TextResources.Warehouse.InspectionOperationsErrorsAlert}
                                    ></i>
                                </span>
                            </Column>
                        )}
                        <Column className="action-col text-right">
                            {!this.props.disableOperationsVariations && (
                                <ColumnHeader>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-xs"
                                        style={{ marginBottom: "3px" }}
                                        data-bind={{
                                            click: inspectionEditor.addWarehouseInspection.bind(inspectionEditor),
                                        }}
                                    >
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </ColumnHeader>
                            )}
                            <button
                                type="button"
                                className="btn btn-xs"
                                data-bind={{ toggle: warehouseInfo.model.ShowOperations }}
                            >
                                <i
                                    className="fa"
                                    data-bind={{
                                        css: {
                                            "fa-chevron-down": !warehouseInfo.model.ShowOperations(),
                                            "fa-chevron-up": warehouseInfo.model.ShowOperations,
                                        },
                                    }}
                                ></i>
                            </button>
                            {!this.props.disableOperationsVariations && (
                                <button
                                    type="button"
                                    className="btn btn-danger btn-xs"
                                    data-bind={{
                                        asyncClick: inspectionEditor.deleteWarehouseInspection.bind(
                                            inspectionEditor,
                                            warehouseInfo.model
                                        ),
                                    }}
                                >
                                    <i className="fa fa-trash-o"></i>
                                </button>
                            )}
                        </Column>
                        <SecondaryRow visible={() => "warehouseInfo.model.ShowOperations"}>
                            {(item: ITableItem<WarehouseInspectionWarehouseInfo>) => (
                                <td colSpan={this.props.isDraft ? 5 : 6}>
                                    <InspectionOperationsInfo
                                        operations={item.Data.model.Operations}
                                        factory={this.operationFactory.bind(this)}
                                        isDraft={!!this.props.isDraft}
                                        disableOperationsVariations={this.props.disableOperationsVariations}
                                    ></InspectionOperationsInfo>
                                </td>
                            )}
                        </SecondaryRow>
                    </Table>
                )}
            </With>
        );
    }

    private onWarehousesSelectionChanges(warehouses: number[]): void {
        this.selectedWarehouses = warehouses;
        this.applyFilters();
    }

    private onCustomersSelectionChanges(customers: number[]): void {
        this.selectedCustomers = customers;
        this.applyFilters();
    }

    private onJobOrdersSelectionChanges(jobOrders: number[]): void {
        this.selectedJobOrders = jobOrders;
        this.applyFilters();
    }

    @Delay(100)
    private applyFilters(): void {
        const allItems = this.OperationsInfo();
        const filteredItems = [];

        for (const item of allItems) {
            if (
                this.selectedCustomers.map((i) => i ?? null).indexOf(item.CustomerId ?? null) >= 0 &&
                this.selectedJobOrders.map((i) => i ?? null).indexOf(item.JobOrderId ?? null) >= 0 &&
                this.selectedWarehouses.map((i) => i ?? null).indexOf(item.DestinationWarehouseId() ?? null) >= 0
            )
                filteredItems.push(item);
        }

        this.FilteredOperationsInfo(filteredItems);
    }

    private articleFactory(
        warehouse: WarehouseInspectionWarehouseInfo
    ): IDataSourceModel<number, WarehouseInspectionWarehouseInfo> {
        return {
            id: warehouse.DestinationWarehouseId(),
            title: warehouse.DestinationWarehouse,
            isGroup: false,
            isLeaf: true,
            model: warehouse,
        };
    }

    private operationFactory(
        operation: WarehouseInspectionOperationInfo
    ): IDataSourceModel<number, WarehouseInspectionOperationInfo> {
        return {
            id: operation.ArticleId(),
            title: operation.ArticleCode + " - " + operation.ArticleDescription,
            isGroup: false,
            isLeaf: true,
            model: operation,
        };
    }
}
